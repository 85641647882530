import React from 'react'
import { Header } from '../Header/Header'
import './Home.css'
import hero_image from '../../assets/hero_image.png'
import hero_image_back from '../../assets/hero_image_back.png'
import Heart from '../../assets/heart.png'
import Calories from '../../assets/calories.png'
import { motion } from 'framer-motion'
// import { Link } from 'react-scroll'
// import call from '../../assets/call.png'

export const Home = () => {
    const transition = { type: 'spring', duration: 3 }
    const mobile = window.innerHeight <= 768 ? true : false
  return (
    <div className='home'>
        <div className="blur hero-blur"></div>
        <div className="left-home">
            <Header />
            <div className="the-best-ad">
                <motion.div
                    initial={{ left: mobile ? "165px" : "238px" }}
                    whileInView={{ left: '8px' }}
                    transition={{...transition, type: 'tween'}}
                 ></motion.div>
                <span>the best unisex fitness club in andul</span>
            </div>
            <div className="hero-text">
                <div>
                    <span className='stroke-text'>Shape </span>
                    <span>Your</span>
                </div>
                <div>
                    <span>Ideal body</span>
                </div>
                <div>
                    <span>
                        In here we will help you to shape and build your ideal body and live up your life to fullest
                    </span>
                </div>
            </div>
        </div>
        <div className="right-home">
            {/* <div className="image-txt-container">
                <i className='fas fa-phone' style={{ fontSize: '48px', color: 'red' }} />
                <img src={call} alt="9051312070" width='50' height='50' style={{ marginTop: '30px', paddingLeft: '5px' }} />
                <h2>9051312070</h2>
            </div> */}
            {/* <button className='btn'>
                <Link to='Join' spy={true} smooth={true}>Join Now</Link>
            </button> */}
            <motion.div
                initial={{ right: "-1rem" }}
                whileInView={{ right: '4rem' }}
                transition={transition}
                className="heart-rate">
                <img src={Heart} alt="" />
                <span>Heart Rate</span>
                <span>116 bpm</span>
            </motion.div>
            <img src={hero_image} alt="" className='hero-image'/>
            <motion.img
                initial={{ right: '11rem' }}
                whileInView={{ right: '20rem' }}
                transition={{ transition }} 
                src={hero_image_back} 
                alt="" 
                className='hero-image-back'
            />
            <motion.div 
                initial={{ right: "37rem" }}
                whileInView={{ right: "28rem" }}
                transition={{ transition }}
                className="calories">
                <img src={Calories} alt="" />
                <div>
                    <span>Calories Burned</span><span>220 kcal</span>
                </div>
            </motion.div>
        </div>
    </div>
  )
}
