import './App.css';
import Footer from './components/Footer/Footer';
import { Home } from './components/Home/Home';
import ImageSlider from './components/ImageSlider/ImageSlider';
import Join from './components/Join/Join';
import { Programs } from './components/Programs/Programs'
import Reason from './components/Reasons/Reason';
import Testimonials from './components/Testimonials/Testimonials';
import { sliderData } from './data/slideData';
function App() {
  return (
    <div className="App ">
        <Home />
        <Programs />
        <Reason />
        <Testimonials />
        <Join />
        <ImageSlider slides={sliderData} />
        <Footer />
    </div>
  );
}

export default App;
