
import React from 'react'
import './Footer.css'
// import Instagram from '../../assets/instagram.png'
// import Facebook from '../../assets/facebook.png'

// import powerhousegymlogo from '../../assets/powerhousegymlogo.png'

const Footer = () => {
  return (
    <div className="Footer-container">
        <hr />
        <div className="footer">
            <div className="social-link">
              {/* <img src={Instagram} alt="" />
              <img src={Facebook} alt="" /> */}
              
              <a href="https://www.facebook.com/profile.php?id=100084050588055" class="fa fa-facebook"></a>

              <a href="https://instagram.com/powerhouse6558?igshid=YmMyMTA2M2Y" class="fa fa-instagram"></a>

              {/* <FontAwesomeIcon icon="fa-brands fa-react" /> */}
            </div>
            {/* <span style={{ fontWeight: 'bold' }}>Call Us: +919051312070</span> */}
            {/* <h1 style={{ fontStyle: 'oblique' }}>PowerHouse Gym</h1> */}
        </div>
        {/* <div className="logo-f">
            <img src={powerhousegymlogo} alt="" />
        </div> */}
        <div className="blur blur-f-1"></div>
        <div className="blur blur-f-2"></div>
    </div>
  )
}

export default Footer