import React, { useState } from 'react'
import './Header.css'
import { Link } from 'react-scroll'
import Bars from '../../assets/bars.png'
// import icon from '../../assets/gymicon.png'
// import powerhousegymlogo from '../../assets/powerhousegymlogo.png'
// import newLogo from '../../assets/newLogo.jpeg'
export const Header = () => {
  const mobile = window.innerWidth <= 768 ? true : false
  const [menuOpend, setMenuOpened] = useState(false)
  return (
    <div className='header'>
        <h1 style={{ fontStyle: 'oblique', color: '#ffd300', fontWeight: '900', fontSize: '350%' }}>PowerHouse Gym</h1>
        {/* <img src={icon} alt="" /> */}
        {/* <img className='logo' src={newLogo} alt="" /> */}
        {(menuOpend === false && mobile === true) ? (
          <div 
            style={{ backgroundColor: 'var(--appColor)', padding: '0.5rem', borderRadius: '5px', cursor: 'pointer' }}
            onClick={() => setMenuOpened(true)}
          >
            <img src={Bars} alt="" style={{ width: '1.5rem', height: '1.5rem' }} />
          </div>
        ): (
        <ul className='header-menu'>
          <li>
            <Link to='Programs' spy={true} smooth={true} onClick={() => setMenuOpened(false)}>
              Programs
            </Link>
          </li>
          <li>
            <Link to='Reasons' spy={true} smooth={true} onClick={() => setMenuOpened(false)}>
              Why Us
            </Link>
          </li>
          <li>
            <Link to='Testimonials' spy={true} smooth={true} onClick={() => setMenuOpened(false)}>
              Testimonials
            </Link>
          </li>
        </ul>
      )}
        
    </div>
  )
}
