import React from 'react'
// import { useRef } from 'react'
import './Join.css'
// import emailjs from '@emailjs/browser'
// import { useState } from 'react'
// import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const Join = () => {
//   const [name, setName] = useState('')
//   const [phoneNumber, setPhoneNumber] = useState('')
//   const [email, setEmail] = useState('')
//   const form = useRef()
//   const showToastMessage = () => {
//     console.log('here');
//     toast.success('You Have Successfully Registered!', {
//         position: toast.POSITION.TOP_RIGHT
//     })
//   }
//   const sendEmail = (e) => {
//     e.preventDefault();

//     emailjs.sendForm('service_vam0q5t', 'template_24j0z87', form.current, 'k62YEUK41-iYFDHJT')
//       .then((result) => {
//         //   console.log(result.text);
//           showToastMessage()
//           setName('')
//           setPhoneNumber('')
//           setEmail('')
//       }, (error) => {
//           console.log(error.text);
//       });
//   }
  return (
    <div className='Join' id='join-us'>
        {/* <div className="left-j">
            <hr />
            <div>
                <span className='stroke-text'>READY TO</span>
                <span>LEVEL UP</span>
            </div>
            <div>
                <span>YOUR BODY</span>
                <span className='stroke-text'>WITH US?</span>
            </div>
        </div> */}
        <div className="right-j">
            {/* <span style={{ fontWeight: 'bold' }}>Call Us: +919051312070</span> */}
            {/* <form ref={form} className='email-container' onSubmit={sendEmail}>
                <input 
                    required 
                    type="text" 
                    name='from_name' 
                    placeholder='Name'
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
                <input 
                    required 
                    type="text" 
                    name='phone_number' 
                    placeholder='Phone Number' 
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                />
                <input 
                    required 
                    type="email" 
                    name='user_email' 
                    placeholder='Email Address' 
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <button className='btn btn-j'>Join Now</button>
                <ToastContainer />
            </form> */}
            <a
                href="https://wa.me/+919051312070"
                class="whatsapp_float"
                target="_blank"
                rel="noopener noreferrer"
            >
                <i class="fa fa-whatsapp whatsapp-icon"></i>
            </a>
        </div>
    </div>
  )
}

export default Join