import React, {useState} from 'react'
import './ImageSlider.css'
import {sliderData} from '../../data/slideData'
import {FaArrowAltCircleRight, FaArrowAltCircleLeft} from 'react-icons/fa'

const ImageSlider = ({ slides }) => {
  const [current, setCurrent] = useState(0)
  const length = slides.length
  const prevSlide = () => {
    setCurrent(current === 0 ? length - 1 : current - 1)
  }
  const nextSlide = () => {
    setCurrent(current === length - 1  ? 0 : current + 1)
  }
  // console.log(current);
  if (!Array.isArray(slides) || slides.length <= 0) {
    return null
  }
  return (
    <section className='slider'>
      <FaArrowAltCircleLeft className='left-arrow' onClick={prevSlide} />
      <FaArrowAltCircleRight className='right-arrow' onClick={nextSlide} />
        {
          sliderData.map((item, index) => {
            return (
              <div className={index === current ? 'slide active' : 'slide'} key={index}>
                {index === current && (
                  <img className='image' src={item.image} alt="" />
                )}
              </div>
            )
          })
        }
    </section>
  )
}

export default ImageSlider