import React from 'react'
import './programs.css'
import { programsData } from '../../data/programsData'
import { useState } from 'react';
// import { Video } from '../Video/Video'
// import { Player } from 'video-react'
// import { CardMedia } from '@mui/material';
import RightArrow from '../../assets/rightArrow.png'
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
// import video1 from '../../data/video1.mp4'
// import video2 from '../../data/video2.mp4'
import mergeVideo from '../../data/merge_video.mp4'
import fat from '../../data/fat.mp4'
import cardio from '../../data/cardio.mp4'
import ReactPlayer from 'react-player'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  height: 500,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export const Programs = () => {
  const [modalOpen, setModalOpen] = useState(false)
  const [modalIndex, setModalIndex] = useState('')
  const [modalHeading, setModalHeading] = useState('')
  const [modalVideo, setModalVideo] = useState('')

  const clickOnProgram = (index, heading) => {
    // console.log('clicked', index);
    setModalOpen(!modalOpen)
    setModalIndex(index)
    setModalHeading(heading)
    setModalVideo(heading)
    if (index === 0) {
      setModalVideo(mergeVideo)
    } else if (index === 1) {
      setModalVideo(cardio)
    } else if (index === 2) {
      setModalVideo(fat)
    }
  }
  const handleClick = () => {
    // console.log('close');
    setModalOpen(!modalOpen)
  }
  
  return (
    <div className="Programs" id="programs">
      <div className="programs-header">
        <span className='stroke-text'>Explore our</span>
        <span>Programs</span>
        <span className='stroke-text'>to shape you</span>
      </div>
      <div className="program-categories" >
        {programsData.map((program, index) => (
          <div className="category" key={index} onClick={() => clickOnProgram(index, program.heading)}>
            {program.image}
            <span>{program.heading}</span>
            <span>{program.details}</span>
            {/* <span>{index}</span> */}
            <div className="join-now">
              <span>Check Here</span>
              <img src={RightArrow} alt="" />
            </div>
          </div>
        ))}
      </div>
      <Modal
        open={modalOpen}
        onClose={handleClick}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {/* {{ index }} */}
            {/* { modalHeading } */}
          </Typography>
          {/* <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Video will be played Here
          </Typography> */}
          {/* <CardMedia 
            component="video"
            image={'../../assets/video1.mp4'}
            title={modalHeading}
            controls
          /> */}
          <ReactPlayer
            className='react-player fixed-bottom'
            url= {modalVideo}
            width='100%'
            height='100%'
            controls = {true}
            muted={true}
          />
        </Box>
      </Modal>
    </div>
  )
}
