// import image1 from "../assets/t-image1.png";
// import image2 from "../assets/t-image2.jpg";
// import image3 from "../assets/t-image3.jpg";
import coach from '../assets/coach.jpeg'
import avatar from '../assets/avatar.jpeg'
import femaleAvatar from '../assets/femaleavatar.jpeg'
export const testimonialsData = [
  {
    image: coach,
    review: 'When you’re dedicated to living a healthy lifestyle, it can be tough to find like-minded people who will support you, let alone someone you can work out with. But at a powerhouse gym, everyone is there to get fit and improve their health. Having someone to meet for a fitness class or go out for coffee with afterward is a great motivator, and makes every workout more fun.',
    name: 'Sajal Banerjee',
    status: 'COACH'
  },
  {
    image : avatar,
    review:'I would give 5/5 stars to the gym, has everything for an average gym freak would need of. Fantastic and expert staff always ready to help. Trainers are available all the time. People are super friendly. Slots are always available. Can’t ask for anything more.',
    name: 'AGNI MULLICK',
    status: "DATA ENGINEER"
  },
  {
    image: femaleAvatar,
    review: 'I have improved my strength, confidence. The environment of gym is friendly, trainers are also friendly. Trainers are very expert as they explain in brief each and every exercise deeply and patience fully till you got it, the gym have all modern equipment, my stress has decrease, I would also recommend to enroll in the gym, especially for students as they got special discount.',
    name: 'MOUMITA DAS',
    status: "STUDENT"
  },
  {
    image: avatar,
    review: 'Due the pandemic I was completely locked in my room, due to that I hadn’t even got a single chance to perform any extra circular activities so my body got super lazy and as a result I cannot perform any task which requires simples body movement. So, after completing my boards I have been recommended about powerhouse gym, after having a session with the expert trainer of powerhouse gym I have given a try to it and now I have found my body structure at a better posture compared to my previous self. I would highly recommend this amazing gym to the people who want to find their body at a better posture.',
    name: 'RUPAM DAS',
    status: 'STUDENT'
  },
  {
    image: femaleAvatar,
    review: 'I have improved my strength, confident and energy. I feel mentally strong, workout had Improve my digestion and health as well. It had help to focus on my studies as my concentration level has increase, I have found good ambiance in the gym, trainer is very helpful, people are also friendly. Would recommend for student as pocket friendly. Best gym in locality.',
    name: 'SUCHITA DAS',
    status: 'STUDENT'
  },
  {
    image: avatar,
    review: 'I have found the best gym in my locality; trainers are very helpful and friendly in nature. Only in two months with the help of proper diet and workout chart provided to me by the trainer I have got a huge weight loss which was my main target. Beside of the trainers the people are all very supportive in exercise, they also provide guidance in my workout.',
    name: 'SOUMYADEEP DAS',
    status: 'STUDENT'
  },
  {
    image: femaleAvatar,
    review: 'within few days only I have reduced my weight loss which was my main focus, after workout I feel totally relax and energetic all the day. I have found my mind more stable from previous. The gym provides totally focused training with care to every individual. The tips provided by trainer are very helpful for health. I would recommend to try powerhouse gym for your development',
    name: 'SUSMITA BOSE',
    status: 'HOUSE WIFE'
  },
  {
    image: femaleAvatar,
    review: 'Good gym and got proper guidance by the trainer. One of the best unisex gym in Andul.',
    name: 'ANKITA BORAL',
    status: 'NUTRITIONIST'
  }
];
