import React from 'react'
import './Reasons.css'
import image1 from '../../assets/image1.png'
import image2 from '../../assets/image2.png'
import image3 from '../../assets/image3.png'
import image4 from '../../assets/image4.png'
import tick from '../../assets/tick.png'

const Reason = () => {
  return (
    <div className="Reasons" id="reasons">
        <div className="left-r">
            <img src={image1} alt="" />
            <img src={image2} alt="" />
            <img src={image3} alt="" />
            <img src={image4} alt="" />
        </div>
        <div className="right-r">
            <span>some reasons</span>
            <div>
                <span className='stroke-text'>why</span>
                <span> choose us?</span>
            </div>
            <div className='details-r'>
                <div>
                    <img src={tick} alt="" />
                    <span>TRAIN SMARTER AND FASTER THAN BEFORE</span>
                </div>
                <div>
                    <img src={tick} alt="" />
                    <span>RELIABLE PARTNERS</span>
                </div>
                <div>
                <img src={tick} alt="" />
                    <span>BOOST YOUR ENERGY AND MOOD</span>
                </div>
                <div>
                    <img src={tick} alt="" />
                    <span>REACH YOUR GOALS</span>
                </div>
                <div>
                    <img src={tick} alt="" />
                    <span>IMPROVE YOUR HEALTH</span>
                </div>
            </div>
        </div>
        
    </div>
  )
}

export default Reason