import image1 from '../assets/pic1.jpeg'
import image2 from '../assets/pic2.jpeg'
import image3 from '../assets/pic3.jpeg'
import image4 from '../assets/pic4.jpeg'
import image5 from '../assets/pic5.jpeg'
import image6 from '../assets/pic7.jpeg'
import image7 from '../assets/pic8.jpeg'
import gym_image from '../assets/gymphoto.jpeg'
export const sliderData = [
    {
        image: gym_image
    },
    {
        image: image1
    },
    {
        image: image2
    },
    {
        image: image3
    },
    {
        image: image4
    },
    {
        image: image5
    },
    {
        image: image6
    },
    {
        image: image7
    }
];